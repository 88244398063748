import React from 'react'
import { bimpConfig } from '../bimpConfig'
import { BimpApp } from 'bimp-ui'
import { Config } from 'bimp-ui/src/types/Config'

interface State {
    config: Partial<Config>
}

export class BimpPopup extends React.Component<{}, State> {
    state: State = {
        config: {
            ...bimpConfig
        }
    }

    render() {
        const { config } = this.state

        return <div className="bimp-popup">{typeof window !== 'undefined' && <BimpApp config={config as Config} />}</div>
    }
}
